import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface Store {
  menu: Menu | null
  company: Company | null
  home: Home | null
  contact: ContactUs | null
  articles: Article[]
  faq: Faq | null
  gallery: Gallery | null
  aboutUs: AboutUs | null
  options: Options | null
  setOptions(options: Options): void
  setGallery(gallery: Gallery): void
  setAboutUs(aboutUs: AboutUs): void
  setArticles(articles: Article[]): void
  setFaq(faq: Faq | null): void
  setContact(contact: ContactUs | null): void
  setHome(home: Home | null): void
  setMenu(menu: Menu | null): void
  setCompany(company: Company | null): void
}

const useStore = create<Store>()(
  devtools(
    persist((set) => ({
      menu: null,
      company: null,
      home: null,
      contact: null,
      articles: [],
      aboutUs: null,
      faq: null,
      gallery: null,
      options: null,
      setOptions: (options: Options | null) =>
        set((state) => ({ ...state, options })),
      setGallery: (gallery: Gallery | null) =>
        set((state) => ({ ...state, gallery })),
      setAboutUs: (aboutUs: AboutUs | null) =>
        set((state) => ({ ...state, aboutUs })),
      setArticles: (articles: Article[]) =>
        set((state) => ({ ...state, articles })),
      setFaq: (faq: Faq | null) => set((state) => ({ ...state, faq })),
      setHome: (home: Home | null) => set((state) => ({ ...state, home })),
      setContact: (contact: ContactUs | null) =>
        set((state) => ({ ...state, contact })),
      setMenu: (menu: Menu | null) => set((state) => ({ ...state, menu })),
      setCompany: (company: Company | null) =>
        set((state) => ({ ...state, company })),
    }), {
      name: 'store', // nommer la clé de stockage pour le persist
    })
  )
)

export default useStore